.btn {
  padding: .63rem 1.5rem;
  font-size: 1.12rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.12rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
}

.popover-header {
  font-size: .75rem;
  padding: .25rem, .5rem;
  font-weight: 700;
}

.popover-body { font-size: .75rem }

.badge { font-weight: 500 }
.badge-warning {
  background-color: $yellow;
  color: $dark-purple;
}
