.paghotel-machine {
  width: 144px;
  max-width: 100%;
}

.steps-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    background-color: $gray-blue;
    border-radius: 8px;
    padding: 1.5rem 1.5rem 1.5rem calc(1.5rem + 27px);
    margin-left: 27px;
    margin-bottom: 1rem;
    counter-increment: inst;
    color: $dark-purple;

    h5 {
      font-family: Poppins;
      color: $dark-purple;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      margin-bottom: .5rem;
    }

    &::before {
      content: counter(inst);
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 54px;
      height: 54px;
      margin-top: -27px;
      margin-left: -27px;
      border-radius: 50%;
      background-color: $yellow;
      text-align: center;
      font-family: Poppins;
      font-size: 1.5rem;
      line-height: 54px;
      font-weight: 700;
      color: $dark-purple;
    }
  }
}

.paghotel-benefits {
  color: $dark-purple;

  & > div {
    border-top: 1px solid #E5E9F2;
  }

  h4 {
    font-family: Poppins;
    font-size: 1.12rem;
    font-weight: 700;
  }

  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.prices-card {
  background-color: #FFF;
  border-radius: 4px;
  position: relative;
  color: $dark-blue;

  h3 {
    font-family: Poppins;
    font-size: 1.5rem;
    border-bottom: 1px solid #D6DDEE;
    padding-bottom: 1rem;
    line-height: 1.7rem;

    .price-badge {
      display: block;
      padding: 7px 8px;
      float: right;
      border-radius: 4px;
      font-size: 0.87rem;
      line-height: 0.71rem;
      color: #FFF;
      font-family: Roboto;
      font-weight: 300;
    }
  }

  .check-list {
    display: block;
    font-size: 0.87rem;
    line-height: 1.5rem;
    vertical-align: middle;
    margin-bottom: .5rem;
    margin-top: .5rem;

    svg {
      color: $green;
      width: 16px;
      height: 16px;
      margin-right: .5rem;
      stroke-width: 3;
    }
  }

  .price-value {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.15rem;

    b {
      font-size: 2.4rem;
      font-weight: 700;
      vertical-align: middle;
    }
  }

  .price-item {
    border-bottom: 1px solid #D6DDEE;
    margin-bottom: 1.5rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0 !important;
    }
  }
}

.price-card-footer {
  padding: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: -3px;
  font-size: .7rem;
  font-weight: 300;

  & > div {
    display: block;

    &:first-child::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #FFF;
      margin-top: .5rem;
      opacity: .6;
    }
  }
}

.paghotel-holders {
  text-align: center;

  img {
    width: 51px;
    margin: 0 .5rem 1rem;
  }
}

.tax-card {
  background-color: #FFF;
  h4 {
    font-size: 1.5rem;
  }

  .tax-payment-list {
    font-size: 1.12rem;
    .tax-payment {
      margin-bottom: 1.5rem;
      font-weight: 500;
    }
    span {
      display: block;
      font-size: .87rem;
      line-height: 1.2rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #D6DDEE;
    }
  }
}

.tax-info {
  font-size: .75rem;
  line-height: 1.3rem;
  img {
    width: 50px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.benefits-item {
  position: relative;
  font-size: 1.12rem;
  line-height: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #D6DDEE;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }

  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: -42px;
    margin-top: -10px;
    color: $green;
  }
}

.insurance-steps {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    counter-increment: insurance-inst;
    padding-bottom: 3rem;

    .step-title {
      position: relative;
      font-size: 1.12rem;
      line-height: 1.5rem;
      font-family: Poppins;
      padding-left: 72px;
      font-weight: 700;
      margin-bottom: 2rem;

      &::before {
        content: counter(insurance-inst);
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 48px;
        height: 48px;
        margin-top: -24px;
        border-radius: 50%;
        background-color: $light-blue;
        text-align: center;
        font-family: Poppins;
        font-size: 1.5rem;
        line-height: 48px;
        font-weight: 700;
        color: #FFF;
        z-index: 1;
      }
    }

    &::after {
      content: '';
      display: none;
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      left: 23px;
      background: #D6DDEE;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.step-footer {
  padding: 1.5rem;
  border-top: 1px solid #D6DDEE;
  margin-right: -1rem;
  margin-left: -1rem;
  font-size: .87rem;
  margin-top: 4rem;

  svg {
    color: $purple-neon;
    margin-right: .5rem;
  }

  a {
    color: $purple-neon;
    text-decoration: underline;
  }
}

@include media-breakpoint-up(md) {
  .paghotel-benefits {
    & > div {
      border-top: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .paghotel-machine {
    width: auto;
  }

  .steps-list {
    li { margin-bottom: 2rem; }
  }

  .paghotel-benefits {
    .icon {
      width: 64px;
      height: 64px;
      line-height: 60px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .prices-card {
    h3 {
      border-bottom: none;
      padding-bottom: 0;
      .price-badge {
        position: absolute;
        top: -8px;
        left: -8px;
      }
    }

    .btn-lg {
      border-radius: 2rem !important;
    }

    .side-separators {
      border-left: 1px solid #D6DDEE;
      border-right: 1px solid #D6DDEE;
    }

    .price-item {
      margin-bottom: 0;
    }
  }

  .price-card-footer {
    & > div {
      display: inline-block;
      padding: 0 1rem;

      &:first-child {
        border-right: 1px solid #FFF;
        &::after {
          display: none;
        }
      }
    }
  }

  .paghotel-holders {
    max-width: 670px;
    margin: 0 auto;
    img {
      width: 88px;
      margin: 0 1.25rem 2.5rem;
    }
  }

  .tax-info {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .tax-card {
    background-color: $card-gray;
    .tax-payment-list {
      border-right: 1px solid #D6DDEE;
      .tax-payment {
        margin-bottom: 1rem;
      }
      span {
        padding-bottom: none;
        border: none;
      }
    }
  }

  .insurance-steps {
    li {
      padding-bottom: 4rem;
      &::after {
        display: block;
      }
      &:last-child::after {
        display: none;
      }
    }
  }

  .step-footer {
    margin-right: -3rem;
    margin-left: -3rem;
    font-size: 1rem;
  }
}
