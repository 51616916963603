.skeleton {
  background: linear-gradient(110deg, #f2f4f7 10%, #f9fafb 25%, #f2f4f7 33%);
  background-size: 200% 100%;
  animation: 2s loading linear infinite;
  &_circle {
    border-radius: 50%;
    &-sm {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
    &-md {
      width: 64px;
      height: 64px;
      object-fit: contain;
    }
    &-lg {
      width: 96px;
      height: 96px;
      object-fit: contain;
    }
  }

  &_square {
    border-radius: 6px;
    &-sm {
      min-width: 48px;
      min-height: 48px;
    }
    &-md {
      min-width: 64px;
      min-height: 64px;
    }
    &-lg {
      min-width: 96px;
      min-height: 96px;
    }
  }

  &_default {
    width: 100%;
    border-radius: 4px;

    &-sm {
      // width: 48px;
      height: 16px;
    }
    &-md {
      // width: 64px;
      height: 24px;
    }
    &-lg {
      // width: 96px;
      height: 40px;
    }
  }
}

@keyframes loading {
  to {
    background-position-x: -200%;
  }
}
