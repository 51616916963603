.bg-white {
  background-color: #fff;
}

.bg-purple {
  background-color: $purple;
}

.bg-blue {
  background-color: $dark-blue;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-opacity-blue {
  border-radius: 4px;
  background: rgba(69, 195, 213, 0.2);
  padding: 4px 16px;
}

.bg-green-blue {
  background-color: $green-blue;
}
.bg-opacity-green {
  border-radius: 4px;
  background: rgba(50, 187, 170, 0.2);
  padding: 4px 16px;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-gray-blue {
  background-color: $gray-blue;
}

.bg-dark-purple {
  background-color: $dark-purple;
}

.bg-light-red {
  background-color: $light-red;
}

.bg-red {
  background-color: $red;
}

.bg-green {
  background-color: $green;
}

.bg-black-purple {
  background-color: $black-purple;
}

.bg-black-hotelflix-navbar {
  background-color: #000;
}

.bg-black-hotelflix-newsletter {
  background-color: #222;
}

.bg-black-hotelflix-content {
  background: #4e4e56;
}

.bg-aqua {
  background: $aqua;
}

.bg-purple-neon {
  background: $purple-neon;
}

.bg-card-gray {
  background: $card-gray;
}
