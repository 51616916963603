@mixin transform($property) {
  -webkit-transform: $property;
     -moz-transform: $property;
       -o-transform: $property;
          transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
     -moz-transition: $property;
       -o-transition: $property;
          transition: $property;
}
