.tag {
  padding: 4px 8px;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &-blue {
    border: 1px solid $dark-blue;
  }

  &-aqua {
    border: 1px solid $light-blue;
  }

  &-yellow {
    border: 1px solid $yellow;
  }
}
