.success-page {
  img {
    display: block;
    width: 260px;
    margin: 0 auto;
  }

  p {
    max-width: 680px;
    margin: 0 auto;
  }
}
