.popover {
  color: $purple-text;
  border: 2px solid #e5e9f2;
}

.popover-header {
  background-color: #e5e9f2;
}

.popover-body {
  color: $purple-text;
}
