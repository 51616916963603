$purple: #765aa6;
$purple-text: #536580;
$dark-purple: #29415e;
$yellow: #fbde64;
$light-blue: #45c3d5;
$dark-blue: #406591;
$gray: #656369;
$gray-blue: #f3f6fd;
$green-blue: #3cacbd;
// $light-green: #45C3D5
$red: #f06672;
$light-red: #ff8791;
$card-gray: #eaeff6;
$green: #32bbaa;
$black-purple: #1e3045;
$dark-purple-icon: #406591;
$aqua: #4ddaf0;
$purple-neon: #8b66cc;
$blue-link: #1686ff;
