.padding-y {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.padding-y-1 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.padding-y-2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.container-separator {
  width: 100%;
  height: 1px;
  background-color: #eaeff6;
}

.padding-y-fixed {
  padding-top: 9rem;
  padding-bottom: 8rem;
}

.padding-y-1-fixed {
  padding-top: 16rem;
  padding-bottom: 14rem;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 2rem;
}

@include media-breakpoint-up(lg) {
  .padding-y {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .padding-y-1 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .padding-y-2 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}