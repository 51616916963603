.noUi-horizontal {
  height: 25px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -3px;
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 2px;
    background-color: #FFF;
  }

  .noUi-base {
    z-index: initial;
  }

  .noUi-connects {
    position: absolute;
    height: 5px;
    border-radius: 2px;
    top: 50%;
    margin-top: -3px;
    z-index: 1;
  }

  .noUi-connect {
    background-color: $dark-blue;
  }
}

.noUi-target {
  background-color: #d6ddee;
  border: none;
  border-radius: 13px;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  width: 30px;
  height: 30px;
  top: -3px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.noUi-handle {
  background-color: $dark-purple;
  border-radius: 50%;
  box-shadow: none;
  border: none;
  z-index: 2;

  &::before { display: none }
  &::after {
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $purple;
  }
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -15px;
}

.noUi-pips-horizontal {
  top: 0;
  padding: 0;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 25px;
}

.noUi-marker-large {
  background-color: #FFF;
  z-index: 1;
}

.noUi-value-horizontal {
  transform: translate(-50%, 150%);
}

