@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "animate.css";
@import "owl.carousel/dist/assets/owl.carousel.css";
@import "owl.carousel/dist/assets/owl.theme.default.css";
@import "icons";
@import "fonts";
@import "colors";
@import "override";
@import "mixins";
@import "typography";
@import "spacings";
@import "buttons";
@import "navbar";
@import "navbar_mobile";
@import "form";
@import "backgrounds";
@import "masks";
@import "banners";
@import "cards";
@import "footer";
@import "plans";
@import "accordion";
@import "carousel";
@import "modal";
@import "slider";
@import "popover";
@import "sidebar";
@import "kit";
@import "paghotel";
@import "cookies";
@import "feedbacks";
@import "testimonials";
@import "tags";
@import "loading";

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tally-popup, .tally-loading-indicator {
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0);
}

@include media-breakpoint-up(xl) {
  body {
    font-size: 1.12rem;
  }
}
