.custom-select-arrow {
  position: relative;

  select {
    appearance: none;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-image: url("../images/icons/chevron-down.png");
    background-repeat: no-repeat;
  }
}

.form-check-inline {
  margin-right: 1rem;
}

// .form-checkbox {
//   display: flex;
//   width: 24px;
//   height: 24px;
//   padding: 3px;
//   align-items: flex-start;
//   border-radius: 4px;
//   border: 1px solid #cfd9e7 !important;
//   // background: #fff;
// }

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: #765aa5;
  width: 24px;
  height: 24px;
  border: 1.5px solid #cfd9e7;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 10px 10px $dark-purple;
}

input[type="checkbox"]:checked {
  border: 1.5px solid #765aa5 !important;
  // transform: scale(1);
}
input[type="checkbox"]:checked::before {
  // border: 1.5px solid #765aa5 !important;
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  color: $gray;
  cursor: not-allowed;
}

.form-textarea {
  border-radius: 24px !important;
  resize: none !important;
  // border: 1px solid $input-border-color;
  // background: $white;
}

.form-radio {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    display: block;
    margin-top: 2px;
    padding-left: 35px;
    font-family: Poppins, sans-serif;
    font-size: 0.87rem;
    font-weight: 500;
    color: $dark-purple;
    line-height: 1.71;
    cursor: pointer;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    border: solid 1px #dfe2eb;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: $dark-purple;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.parsley-errors-list {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  list-style: none;
  color: #f06672;
}

.parsley-error,
.parsley-error.form-control {
  border-color: rgba(#f06672, 0.5);
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(#f06672, 0.25);
  }
}

textarea.form-control {
  border-radius: 6px;
}

.hs--radio {
  label {
    font-weight: 400;
    position: relative;
    padding-left: 35px;
    margin-bottom: 1rem;
    font-size: 0.875rem;

    [type="radio"] {
      position: absolute;
      left: 0;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 24px;
      width: 24px;

      &:before {
        content: "";
        display: block;
        grid-area: radio;
        border: 1px solid #cfd9e7;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:after {
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        left: 0;
        top: 0;
        position: absolute;
        color: white;
        text-align: center;
      }

      &:checked {
        &:before {
          background-color: #223043;
          border-color: #223043;
        }
        &:after {
          content: "\e7f3";
          color: white;
          font-family: "Hospedin Icons";
          font-size: 1.5rem;
          line-height: 24px;
        }
      }
    }
  }
}

.toggle-switch {
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 40px;
  }

  &_item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #37bc9b;
    cursor: pointer;
    border-radius: 25px;
  }

  &_slider {
    background-color: $white;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s ease-in-out;

    &-animation {
      transition: transform 0.3s ease-in-out;
      transform: translateX(16px);
    }
  }
}
