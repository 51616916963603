$nav-mobile-padding-x: 1rem;

.nav-menu-mobile {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #fff;
  overflow-y: auto;
  z-index: 1020;
  padding-bottom: 80px;

  .nav-items,
  .subnav-items {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    > .nav-item {
      display: block;
      width: 100%;
      padding: 1rem $nav-mobile-padding-x;
      border-bottom: 2px solid rgba($dark-blue, 0.1);
      font-family: Poppins, sans-serif;
      font-size: 1.12rem;
      color: $dark-purple;

      &:last-child {
        border-bottom-color: transparent;
      }

      a {
        color: $dark-purple;

        &:hover {
          text-decoration: none;
          color: $dark-purple;
        }
      }
    }
  }

  .subnav-items {
    margin-top: 1rem;

    > .nav-item {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      padding: 0;
      font-size: 1rem;
      font-weight: 700;
      border-bottom: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .login-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      &_btn {
        border: none;
        background-color: transparent;
        display: inline-block;
        color: #765aa5 !important;
        text-decoration: none !important;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
        white-space: nowrap;

        &:hover {
          color: #765aa5;
          text-decoration: none;
        }
      }

      &_chat {
        // background-color: transparent;
        // border: none;
        // border-top: 1px solid #e5e9f2 !important;
        // padding-top: 16px;
        // .dropdown-client-login-footer {
        background-color: transparent;
        width: 100%;
        border: none;
        border-top: 1px solid #e9e9e9;
        color: #2e405c;
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        padding-top: 16px;

        /* header/h6 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        // font-weight: 600;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;

        &:hover {
          color: #765aa5;
          text-decoration: none;
        }
        // }
      }
    }
  }

  .nav-item-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-top: -1px;
    margin-right: 8px;
    padding-top: 3px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

body.nav-mobile-open {
  .nav-menu-mobile {
    display: block;
  }
}

@include media-breakpoint-down(lg) {
  body.nav-mobile-open {
    overflow-y: hidden;
  }
}

@include media-breakpoint-up(lg) {
  .nav-menu-mobile {
    display: none !important;
  }
}
