$btn-border-radius-sm: 1rem;
$btn-border-radius: 1.5rem;
$btn-border-radius-lg: 2rem;
$input-padding-y: 0.75rem;
$input-padding-x: 1.5rem;
$input-height-inner: 2.875rem;
$input-border-radius: 1.5rem;
$input-border-color: #dfe2eb;
$input-placeholder-color: rgba(#536580, .6);
$input-plaintext-color: #536580;
